<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ $t('advertisers') }}</span>
        </h1>
      </div>
      <div class="d-flex">
        <Button
          class="btn btn-primary"
          v-b-modal.upsert-advertiser-modal
          v-if="user && user.company.type === 'AGENCY'"
        >
          {{ $t('new_advertiser') }}
        </Button>
        <CreateOrderButton
          class="ms-3"
          v-if="user"
          :reloadAdvertisers="reload"
          @order-created="reloadAdvertisers"
        />
      </div>
      <UpsertAdvertiserModal
        @advertiser-updated="handleUpsert"
        @advertiser-created="handleUpsert"
      />
    </div>
    <!-- ADVERTISERS -->
    <div class="mt-4">
      <AdvertiserListTable
        :reload="reload"
      />
    </div> <!-- END ADVERTISERS -->
  </div>
</template>

<script>

import { mapState } from 'vuex'

import AdvertiserListTable from '@/components/advertisers/AdvertiserListTable'
import Button from '@/components/common/Button/Button'
import CreateOrderButton from '@/components/orders/CreateOrderButton'
import UpsertAdvertiserModal from '@/components/advertisers/UpsertAdvertiserModal'

export default {
  components: {
    AdvertiserListTable,
    Button,
    CreateOrderButton,
    UpsertAdvertiserModal
  },
  data () {
    return {
      reload: false
    }
  },
  metaInfo () {
    return {
      title: this.$t('advertisers')
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Handle upsert
     */
    handleUpsert (advertiser) {
      this.$router.push({ name: 'advertisers.show', params: { id: advertiser.id } })
    },
    /**
     * Reload advertisers
     */
    reloadAdvertisers () {
      this.reload = true
      setTimeout(() => {
        this.reload = false
      }, 300)
    }
  }
}
</script>
